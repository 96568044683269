<template lang="pug">
div
	client-only(v-if="availableLocales")
		SingaDropdown(aria-role="list" :mobile-modal="false" position="bottom-left")
			template(#trigger="{ active }")
				SingaButton.dropdown-button-regular.is-transparent-dark.is-rounded(
					:label="localeProperties.name"
					:icon-right="active ? 'caret-up-outline' : 'caret-down-outline'")
			ul
				SingaDropdownItem.item(v-for="i in availableLocales" :key="i.code" aria-role="listitem" @click="setLocale(i.code)" tag="li") {{i.name}}
</template>

<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'

const { locales, localeProperties, setLocale } = useI18n()

const availableLocales = computed(() => {
	return (locales.value).filter(i => i.code !== localeProperties.value.code) as LocaleObject[]
})

defineProps({
	fullWidth: {
		type: Boolean,
		required: true
	}
})
</script>

<style lang="sass" scoped>
.item
	background-color: $color-grey-80
	@include font(basier, regular)
	&:hover
		background-color: $transparent-white-8
		color: white
</style>
