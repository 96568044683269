<template lang="pug">
.footer-wrapper
	footer.footer(v-if="story")
		.container.section
			.footer-top
				.footer-left
					.logo-wrap
						SingaLogo.singa-logo(:imgSrc="story.logo.filename" alt="Singa Logo")
						.social-wrap-mobile
							StoryblokLink(v-for="some in story.social_media_links" :blok="some")
					.footer-content
						.footer-column
							span.column-label {{ story.help_links_title }}
							ul.footer-column-wrap
								li(v-if="!hasPremiumSub")
									NuxtLink(to="https://singa.com/choose-plan") {{ t('general.getPremium')}}
								li(v-for="link in story.help_links")
									StoryblokLink(v-if="link.component === 'StoryblokLink'" :blok="link")
									StoryblokSongRequestModal.link-wrapper.link(v-else-if="link.component === 'StoryblokSongRequestModal' && isLoggedIn" :blok="link")
						.footer-column
							span.column-label {{ story.about_links_title }}
							ul.footer-column-wrap
								li(v-for="link in story.about_links")
									StoryblokLink(:blok="link")
						.footer-column
							span.column-label {{ story.other_links_title }}
							ul.footer-column-wrap
								li(v-for="link in story.other_links")
									StoryblokLink(:blok="link")
				.footer-right
					.social-wrap
						StoryblokLink(v-for="some in story.social_media_links" :blok="some")
					LanguageSelect.lang-select-desktop(:fullWidth="true")
					client-only
						StoryblokFooterButton(v-for="button in story.footer_button" :blok="button")

			.language-small-screen
				LanguageSelect.lang-select-mobile(:fullWidth="true")
				client-only
					StoryblokFooterButton.footer-button-mobile(v-for="button in story.footer_button" :blok="button")

			.footer-bottom(:class="{ 'player-visible': miniPlayerIsShowing }")
				ul.footer-terms
					li(v-for="link in story.terms_links" :blok="link")
						StoryblokLink(:blok="link")
				StoryblokLink(v-for="copyright in story.copyright_text" :blok="copyright")
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useUserStore } from '../../pinia/userStore'
import { usePlayerStore } from '~/pinia/player/karaokePlayer'

const karaokeStore = usePlayerStore()
const { showMiniPlayer } = storeToRefs(karaokeStore)

const { isLoggedIn } = useAuth()

const userStore = useUserStore()
const { hasPremiumSub } = storeToRefs(userStore)
const { t } = useI18n()

defineProps({
	story: {
		type: Object,
		default: null
	}
})

const miniPlayerIsShowing = ref(false)

onMounted(() => {
	miniPlayerIsShowing.value = showMiniPlayer.value
})

watch(showMiniPlayer, (newValue, oldValue) => {
	if (newValue !== oldValue) {
		miniPlayerIsShowing.value = newValue
	}
})
</script>

<style lang="sass" scoped>
footer
	background-color: $transparent-white-4
	border-top: 1px solid $transparent-white-12
	padding: $spacing-24 0 $spacing-64
	position: relative
	@media (min-width: $tablet)
		padding: $spacing-48 0 $spacing-16 0
.column-label
	@include fontSize(xs)
	@include font(basier, medium)
	color: $color-grey-50
	padding-bottom: $spacing-16
	display: block
	text-transform: uppercase
.footer-top
	display: flex
	text-align: center
	@media (min-width: $tablet)
		text-align: initial
.footer-left
	display: flex
	flex-grow: 1
	flex-direction: column
	@media (min-width: $tablet)
		flex-direction: row
.footer-right
	display: none
	visibility: hidden
	@media (min-width: $tablet)
		display: flex
		visibility: visible
		display: flex
		flex-direction: column
		align-items: flex-end
.footer-terms
	display: flex
	li
		color: $color-grey-50
		&:not(:last-child)
			padding-right: $spacing-24
	@media (max-width: $tablet)
		justify-content: center
		padding: $spacing-8 0 $spacing-32
.footer-bottom
	display: flex
	justify-content: space-between
	padding-top: $spacing-40
	flex-direction: column
	width: 100%
	text-align: center
	margin-bottom: $spacing-48
	a
		color: $color-grey-50
		flex-shrink: 0
	span
		white-space: nowrap
		justify-content: center
	@media (min-width: $tablet)
		flex-direction: row
		padding-top: 126px
	&.player-visible
		margin-bottom: 104px

.footer-column-wrap
	li
		color: $color-grey-30
		padding: $spacing-8 0
	@media (min-width: $tablet)
		padding-right: $spacing-40
.footer-content
	display: flex
	flex-direction: column
	@media (min-width: $tablet)
		flex-direction: row
		padding-left: $spacing-40
		flex: 1
		max-width: 700px
		justify-content: space-between
	@media (min-width: $desktop)
		padding-left: $spacing-80
.social-wrap
	display: flex
	padding-bottom: $spacing-32
.social-wrap, .social-wrap-mobile
	.link-wrapper:not(:last-child)
		padding-right: $spacing-16
.footer-column
	@media (max-width: $tablet)
		padding-bottom: $spacing-40
.social-wrap-mobile, .lang-select-mobile
	@media (min-width: $tablet)
		display: none
		visibility: hidden
.logo-wrap
	@media (max-width: $tablet)
		display: grid
		grid-template-columns: 1fr 1fr
		grid-template-rows: min-content 1fr
		grid-template-areas: ". ." "lang-select-mobile lang-select-mobile"
		.lang-select-mobile
			grid-area: lang-select-mobile
			width: 100%
			padding: $spacing-24 0 $spacing-40
		.social-wrap-mobile
			display: flex
			align-items: center
			justify-content: flex-end
.singa-logo
	width: 110px

.lang-select-desktop, .footer-button-desktop
	@media (max-width: $tablet)
		display: none

.lang-select-mobile, .footer-button-mobile
	@media (min-width: $tablet)
		display: none

.language-small-screen
	display: none
	@media (max-width: $tablet)
		display: flex
		flex-direction: column
		align-items: center
		padding: $spacing-24 0

:deep(.link)
	justify-content: center
	@media (min-width: $tablet)
		justify-content: flex-start

.copyright-text
	color: $color-grey-30
</style>
